<template>
  <Card class="p-my-4 p-mx-6 p-py-2 p-px-4">
    <template #title>Getting Started</template>
    <template v-if="!isCollapsed" #content>
      <div class="p-grid p-nogutter p-flex-column">
        <!-- Email Validatin Form -->
        <div v-if="!resetRequired">
          <div class="p-col">
            Please enter your email to begin the investment process.
          </div>
          <div class="p-col p-mt-4">
            <div class="p-grid p-nogutter">
              <div class="p-col">
                <span class="p-input-icon-left input-container">
                  <i class="far fa-envelope" />
                  <InputText
                    v-model="email"
                    type="text"
                    placeholder="Email"
                    class="input-text"
                  />
                </span>
              </div>
              <div class="p-col p-text-right">
                <Button
                  v-if="!loginRequired && !tokenRequired && !resetRequired"
                  class="no-box-shadow"
                  label="Next"
                  iconPos="right"
                  :disabled="nextDisabled"
                  @click="checkEmail"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- End Email Validatin Form -->

        <!-- Password Form -->
        <div v-if="loginRequired">
          <div class="p-col p-mt-5">
            You've invested with Capital2Market before. Please login to access
            your information.
          </div>
          <div class="p-col p-mt-4">
            <div class="p-grid p-nogutter">
              <div class="p-col">
                <span class="p-input-icon-left input-container">
                  <i class="far fa-envelope" />
                  <InputText
                    v-model="password"
                    type="text"
                    placeholder="Password"
                    class="input-text"
                  />
                </span>
              </div>
              <div class="p-col p-text-right">
                <Button
                  class="no-box-shadow p-mr-3"
                  label="Forgot Password?"
                  iconPos="right"
                  @click="forgotPassword"
                />
                <Button
                  class="no-box-shadow"
                  label="Login"
                  iconPos="right"
                  @click="login"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- End Password Form -->

        <!-- Token Validation Form -->
        <div v-if="tokenRequired">
          <div class="p-col p-mt-5">
            We have sent an email to the provided address. Please check your
            email in another window and enter the given token below to reset
            your password.
          </div>
          <div class="p-col p-mt-4">
            <div class="p-grid p-nogutter">
              <div class="p-col">
                <span class="p-input-icon-left input-container">
                  <i class="far fa-envelope" />
                  <InputText
                    v-model="token"
                    type="text"
                    placeholder="Token"
                    class="input-text"
                  />
                </span>
              </div>
              <div class="p-col p-text-right">
                <Button
                  class="no-box-shadow"
                  label="Validate Token"
                  iconPos="right"
                  @click="validateToken"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- End Token Validation Form -->

        <!-- Password Reset Form -->
        <div v-if="resetRequired">
          <div class="p-col p-mt-5">
            Please answer the following security questions to verify your
            identity.
          </div>
          <div class="p-col p-mt-4">
            <div class="p-grid p-nogutter input-container">
              <div class="p-col p-d-flex p-pr-4 p-align-center">
                What is your favorite color?
              </div>
              <div class="p-col p-pl-4 p-text-right">
                <InputText
                  v-model="answer1"
                  type="text"
                  placeholder="Answer"
                  class="input-text"
                />
              </div>
            </div>
            <div class="p-grid p-nogutter p-mt-4 input-container">
              <div class="p-col p-d-flex p-pr-4 p-align-center">
                What is your favorite song?
              </div>
              <div class="p-col p-pl-4 p-text-right">
                <InputText
                  v-model="answer2"
                  type="text"
                  placeholder="Answer"
                  class="input-text"
                />
              </div>
            </div>
            <div class="p-grid p-nogutter p-mt-4 input-container">
              <div class="p-col p-d-flex p-pr-4 p-align-center">
                What is the meaning of life?
              </div>
              <div class="p-col p-pl-4 p-text-right">
                <InputText
                  v-model="answer3"
                  type="text"
                  placeholder="Answer"
                  class="input-text"
                />
              </div>
            </div>
          </div>
          <div class="p-col p-mt-5">
            Please create a new password for your account.
          </div>
          <div class="p-col p-mt-5">
            <div class="p-grid p-nogutter input-container">
              <div class="p-col p-pr-4">
                <InputText
                  v-model="password1"
                  type="text"
                  placeholder="New Password"
                  class="input-text"
                />
              </div>
              <div class="p-col p-pl-4 p-text-right input-container">
                <InputText
                  v-model="password2"
                  type="text"
                  placeholder="Confirm New Password"
                  class="input-text"
                />
              </div>
            </div>
          </div>
          <div class="p-col p-mt-4">
            <div class="p-grid p-nogutter">
              <div class="p-col p-text-right">
                <Button
                  class="no-box-shadow"
                  label="Reset Password"
                  iconPos="right"
                  @click="resetPassword"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- End Password Reset Form -->
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref, inject, computed, watch } from "vue";

import { Api } from "@/api";
import {
  PostLogin,
  PostTempLogin,
  PostForgotPassword,
  PostValidatePasswordToken,
  PostResetPassword,
  V1ResponseWrapper,
} from "@/api/services/v1Payloads";

import { C } from "@/interfaces/constants";
import { Login, TempLogin } from "@/interfaces/v1/Login";

export interface EmitEmailData {
  email: string;
  tempLogin: TempLogin;
}

export default defineComponent({
  name: "InvestNowProfileFormGetStarted",
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
    offeringId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const $api = inject("$api") as Api;

    let tokenValidationResp: V1ResponseWrapper;

    // exposed refs
    const isCollapsed = ref(false);
    const email = ref();
    const password = ref();
    const token = ref();
    const postedEmail = ref();
    const answer1 = ref();
    const answer2 = ref();
    const answer3 = ref();
    const password1 = ref();
    const password2 = ref();
    const loginRequired = ref(false);
    const tokenRequired = ref(false);
    const resetRequired = ref(false);

    // local constants
    const userExistsError = "User has already registered";

    // watchers
    watch(
      () => props.collapsed,
      (o, n) => {
        isCollapsed.value = n;
      }
    );

    // computed values
    const nextDisabled = computed(() => {
      if (!C.REGEX.EMAIL.test(String(email.value).toLowerCase())) {
        return true;
      }

      return postedEmail.value === email.value;
    });

    // ui methods
    async function checkEmail() {
      const req: PostTempLogin = {
        email: email.value,
        offering_id: props.offeringId,
      };
      try {
        const { data }: { data: V1ResponseWrapper } = await $api.v1.tempLogin(
          req
        );

        if (data.error === userExistsError) {
          loginRequired.value = true;
        } else {
          if (data.status === "success") {
            postedEmail.value = email.value;

            const emitData = {
              email: email.value,
              tempLogin: data.data as TempLogin,
            };

            emit("tempLogin", emitData);
          }
        }
      } catch (e) {
        // todo: handle/display error
        console.log(e);
      }
    }

    async function login() {
      const req: PostLogin = {
        email: email.value,
        password: password.value,
      };

      try {
        const { data }: { data: V1ResponseWrapper } = await $api.v1.login(req);
        console.log(data);
        if (data.status === "success") {
          console.log("successful login");
          emit("login", data.data as Login);
        } else {
          // todo: handle/display error
        }
      } catch (e) {
        // todo: handle/display error
        console.log(e);
      }
    }

    async function forgotPassword() {
      const req: PostForgotPassword = {
        email: email.value,
      };

      try {
        const { data }: { data: V1ResponseWrapper } =
          await $api.v1.forgotPassword(req);

        if (
          data.status === "success" ||
          data.error === "Current token has not expired"
        ) {
          loginRequired.value = false;
          tokenRequired.value = true;
        } else {
          // todo: handle/display error
        }
      } catch (e) {
        // todo: handle/display error
        console.log(e);
      }
    }

    async function validateToken() {
      const req: PostValidatePasswordToken = {
        token: token.value,
      };

      try {
        const {
          data,
        }: {
          data: V1ResponseWrapper;
        } = await $api.v1.validatePasswordResetToken(req);

        tokenValidationResp = data;

        if (
          data.status === "success" ||
          data.error === "Current token has not expired"
        ) {
          loginRequired.value = false;
          tokenRequired.value = false;
          resetRequired.value = true;
        } else {
          // todo: handle/display error
        }
      } catch (e) {
        // todo: handle/display error
        console.log(e);
      }
    }

    async function resetPassword() {
      console.log(tokenValidationResp.data);

      const req: PostResetPassword = {
        token: token.value,
        user_id: tokenValidationResp.data
          ? tokenValidationResp.data["user_id"]
          : "",
        password: password1.value,
        answers: [
          {
            security_question_id: "1",
            answer_text: answer1.value,
          },
          {
            security_question_id: "3",
            answer_text: answer2.value,
          },
          {
            security_question_id: "4",
            answer_text: answer3.value,
          },
        ],
        user_first: null,
        user_last: null,
      };

      try {
        const {
          data,
        }: {
          data: V1ResponseWrapper;
        } = await $api.v1.resetPassword(req);

        console.log(data);

        if (data.status === "success") {
          loginRequired.value = true;
          tokenRequired.value = false;
          resetRequired.value = false;
        } else {
          // todo: handle/display error
        }
      } catch (e) {
        // todo: handle/display error
        console.log(e);
      }
    }

    return {
      // refs
      isCollapsed,
      email,
      password,
      token,
      loginRequired,
      tokenRequired,
      resetRequired,
      answer1,
      answer2,
      answer3,
      password1,
      password2,
      // computed
      nextDisabled,
      // methods
      checkEmail,
      login,
      forgotPassword,
      validateToken,
      resetPassword,
    };
  },
});
</script>

<style lang="scss" scoped>
.input-container {
  width: 100%;

  .input-text {
    width: 100%;
  }

  .input-selector {
    width: 100%;
  }
}
</style>
