
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormIndividualContactInfo",
  setup() {
    const address1 = ref();
    const address2 = ref();
    const city = ref();
    const state = ref();
    const country = ref();
    const zip = ref();
    const primaryPhone = ref();
    const secondaryPhone = ref();
    const emailAddress = ref();

    function next() {
      console.log("next");
    }

    return {
      // refs
      address1,
      address2,
      city,
      state,
      country,
      zip,
      primaryPhone,
      secondaryPhone,
      emailAddress,
      // methods
      next,
    };
  },
});
