
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormEntityFinancialInfo",
  setup() {
    const toggleOptions = ref(["No", "Yes"]);

    function next() {
      console.log("next");
    }

    return {
      // refs
      toggleOptions,
      // methods
      next,
    };
  },
});
