
import { defineComponent } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormEntityVerificationDocs",
  setup() {
    function upload() {
      console.log("upload");
    }

    function next() {
      console.log("next");
    }

    return {
      // methods
      upload,
      next,
    };
  },
});
