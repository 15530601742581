<template>
  <div class="p-grid p-nogutter p-flex-column">
    <div class="p-col p-my-4 p-text-center">
      <img class="header-img" :src="headerImage" />
    </div>

    <div class="p-col offering-title" :style="offeringStyle">
      <div>Invest in {{ offeringName }}</div>
    </div>

    <!-- Email Check/Login -->
    <div class="p-col">
      <InvestNowProfileFormGetStarted
        :offering-id="offeringId"
        @login="loginSuccess"
        @tempLogin="tempLoginSuccess"
      />
    </div>

    <!-- Investor Type -->
    <div v-if="showInvestorTypeSelector" class="p-col">
      <InvestNowProfileFormInvestorType
        :offering-id="offeringId"
        @typeSelected="investorTypeSelected"
      />
    </div>

    <!-- Individual Investor -->
    <!--    <div v-if="investorType === 'Individual'" class="p-col">-->
    <div class="p-col">
      <InvestNowProfileFormIndividualPersonalInfo />
      <InvestNowProfileFormIndividualContactInfo />
      <InvestNowProfileFormIndividualFinancialInfo />
      <InvestNowProfileFormComplianceInfo />
      <InvestNowProfileFormSuitability />
    </div>

    <!-- Entity Investor -->
    <!--    <div v-if="investorType === 'Entity'" class="p-col">-->
    <div class="p-col">***********************************</div>

    <div class="p-col">
      <InvestNowProfileFormEntityInfo />
      <InvestNowProfileFormEntityRepresentativeInfo />
      <InvestNowProfileFormEntityOwnerInfo />
      <InvestNowProfileFormEntityFinancialInfo />
      <InvestNowProfileFormComplianceInfo />
      <InvestNowProfileFormSuitability />
      <InvestNowProfileFormEntityVerificationDocs />
    </div>

    <div class="p-col p-px-6 p-mb-6">
      <div class="p-grid">
        <div class="p-col-6">
          <div class="agreement-container">
            <p>
              By submitting this profile, I agree under penalty of perjury that:
            </p>
            <p>
              I have completed my own diligence.<br />
              I have read and agree to the terms of service.<br />
              The information I have provided is accurate.<br />
              Any further complaint that I may have will be resolved through
              arbitration.<br />
            </p>
          </div>
        </div>
        <div class="p-col-6 p-text-right">
          <Button
            class="no-box-shadow"
            label="Complete Profile"
            @click="completeProfile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, onMounted, Ref, ref } from "vue";
import { useRoute } from "vue-router";

import InvestNowProfileFormGetStarted, {
  EmitEmailData,
} from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormGetStarted.vue";
import InvestNowProfileFormInvestorType from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormInvestorType.vue";

// Individual Forms
import InvestNowProfileFormIndividualPersonalInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormIndividualPersonalInfo.vue";
import InvestNowProfileFormIndividualContactInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormIndividualContactInfo.vue";
import InvestNowProfileFormIndividualFinancialInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormIndividualFinancialInfo.vue";

// Entity Forms
import InvestNowProfileFormEntityInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormEntityInfo.vue";
import InvestNowProfileFormEntityRepresentativeInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormEntityRepresentativeInfo.vue";
import InvestNowProfileFormEntityOwnerInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormEntityOwnerInfo.vue";
import InvestNowProfileFormEntityFinancialInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormEntityFinancialInfo.vue";
import InvestNowProfileFormEntityVerificationDocs from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormEntityVerificationDocs.vue";

// Individual and Entity Forms
import InvestNowProfileFormComplianceInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormComplianceInfo.vue";
import InvestNowProfileFormSuitability from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormSuitability.vue";

import { Api } from "@/api";
import {
  V1ResponseWrapper,
  GetOfferingCompany,
  PostIcon,
} from "@/api/services/v1Payloads";

import { OfferingCompany } from "@/interfaces/v1/offeringCompany";
import { Login } from "@/interfaces/v1/Login";

export default defineComponent({
  name: "InvestNowProfileForm",
  components: {
    InvestNowProfileFormGetStarted,
    InvestNowProfileFormInvestorType,
    InvestNowProfileFormComplianceInfo,
    InvestNowProfileFormSuitability,
    InvestNowProfileFormIndividualPersonalInfo,
    InvestNowProfileFormIndividualContactInfo,
    InvestNowProfileFormIndividualFinancialInfo,
    InvestNowProfileFormEntityInfo,
    InvestNowProfileFormEntityRepresentativeInfo,
    InvestNowProfileFormEntityOwnerInfo,
    InvestNowProfileFormEntityFinancialInfo,
    InvestNowProfileFormEntityVerificationDocs,
  },
  setup() {
    // Offering ID: FB34EAEB8DA1923B11E7778B26130679

    const route = useRoute();
    const $api = inject("$api") as Api;

    // local data declarations

    // exposed refs
    const offeringId = ref("");
    const investorType = ref();
    const offeringCompany: Ref<OfferingCompany | null> = ref(null);
    const headerImage = ref();
    const offeringStyle = ref({ backgroundColor: "gray" });

    const showInvestorTypeSelector = ref(false);

    // computed values
    const offeringName = computed(() => {
      return offeringCompany?.value?.offering_company_name
        ? offeringCompany?.value?.offering_company_name
        : "offering";
    });

    // api method declarations
    async function loadOfferingCompany() {
      let req: GetOfferingCompany = {
        offering_id: offeringId.value,
      };
      try {
        let { data }: { data: V1ResponseWrapper } =
          await $api.v1.getOfferingCompany(req);
        offeringCompany.value = data.data as OfferingCompany;

        if (offeringCompany.value?.panel_header_color) {
          offeringStyle.value.backgroundColor =
            offeringCompany.value?.panel_header_color;
        }

        await loadOfferingIcon();
      } catch (e) {
        // todo: handle/display error
        console.log(e);
      }
    }

    async function loadOfferingIcon() {
      if (offeringCompany.value?.file_path) {
        let req: PostIcon = {
          file_path: offeringCompany.value?.file_path as string,
        };
        let { data } = await $api.v1.getOfferingHeader(req);
        headerImage.value = window.URL.createObjectURL(data);
      }
    }

    // ui methods
    function loginSuccess(login: Login) {
      console.log(`login: ${login}`);
      showInvestorTypeSelector.value = true;
    }

    function tempLoginSuccess(tempLogin: EmitEmailData) {
      console.log(tempLogin);
      showInvestorTypeSelector.value = true;
    }

    function investorTypeSelected(type: string) {
      console.log(type);
      investorType.value = type;

      // todo: reset investor form data mapping
    }

    function completeProfile() {
      console.log("complete profile");
    }

    // lifecycle hooks
    onMounted(() => {
      offeringId.value = route.params.id as string;

      console.log(offeringId);

      loadOfferingCompany();
    });

    return {
      // refs
      offeringId,
      investorType,
      offeringCompany,
      headerImage,
      offeringStyle,
      showInvestorTypeSelector,
      // computed
      offeringName,
      // ui methods
      loginSuccess,
      tempLoginSuccess,
      investorTypeSelected,
      completeProfile,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-img {
  max-width: 400px;
  max-height: 120px;
  mix-blend-mode: multiply;
}

.offering-title {
  padding: 12px 24px 12px 24px;
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  height: 200px;
  color: white;
}

.agreement-container {
  width: 100%;
  padding: 12px 12px 12px 12px;
  background-color: white;
  border: solid 2px black;
}
</style>

<style lang="scss">
//.v1-form-title {
//  font-size: 14px;
//  font-weight: bold;
//}

//.v1-input-container {
//  //width: 100%;
//
//  .input-text {
//    width: 100%;
//  }
//
//  .input-selector {
//    width: 100%;
//  }
//
//  .width-100 {
//    width: 100%;
//  }
//}

.itin-info-text {
  font-size: 12px;
}
</style>
