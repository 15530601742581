
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormSuitability",
  setup() {
    const timeframe = ref();
    const risk = ref("Yes");
    const experience = ref();

    const toggleOptions = ref(["No", "Yes"]);
    const timeframeOptions = ref([
      "One Year",
      "Three Years",
      "Five Years",
      "Seven or More Years",
    ]);
    const experienceOptions = ref([
      "Novice",
      "Intermediate",
      "Experienced",
      "Seven or More Years",
    ]);

    function next() {
      console.log("next");
    }

    return {
      //refs
      timeframe,
      risk,
      experience,
      toggleOptions,
      timeframeOptions,
      experienceOptions,
      //methods
      next,
    };
  },
});
