
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormComplianceInfo",
  setup() {
    const shareHolder = ref("No");
    const association = ref("No");

    const toggleOptions = ref(["No", "Yes"]);

    function next() {
      console.log("next");
    }

    return {
      //refs
      shareHolder,
      association,
      toggleOptions,
      //methods
      next,
    };
  },
});
