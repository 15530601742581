
import { computed, defineComponent, inject, onMounted, Ref, ref } from "vue";
import { useRoute } from "vue-router";

import InvestNowProfileFormGetStarted, {
  EmitEmailData,
} from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormGetStarted.vue";
import InvestNowProfileFormInvestorType from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormInvestorType.vue";

// Individual Forms
import InvestNowProfileFormIndividualPersonalInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormIndividualPersonalInfo.vue";
import InvestNowProfileFormIndividualContactInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormIndividualContactInfo.vue";
import InvestNowProfileFormIndividualFinancialInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormIndividualFinancialInfo.vue";

// Entity Forms
import InvestNowProfileFormEntityInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormEntityInfo.vue";
import InvestNowProfileFormEntityRepresentativeInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormEntityRepresentativeInfo.vue";
import InvestNowProfileFormEntityOwnerInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormEntityOwnerInfo.vue";
import InvestNowProfileFormEntityFinancialInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormEntityFinancialInfo.vue";
import InvestNowProfileFormEntityVerificationDocs from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormEntityVerificationDocs.vue";

// Individual and Entity Forms
import InvestNowProfileFormComplianceInfo from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormComplianceInfo.vue";
import InvestNowProfileFormSuitability from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormSuitability.vue";

import { Api } from "@/api";
import {
  V1ResponseWrapper,
  GetOfferingCompany,
  PostIcon,
} from "@/api/services/v1Payloads";

import { OfferingCompany } from "@/interfaces/v1/offeringCompany";
import { Login } from "@/interfaces/v1/Login";

export default defineComponent({
  name: "InvestNowProfileForm",
  components: {
    InvestNowProfileFormGetStarted,
    InvestNowProfileFormInvestorType,
    InvestNowProfileFormComplianceInfo,
    InvestNowProfileFormSuitability,
    InvestNowProfileFormIndividualPersonalInfo,
    InvestNowProfileFormIndividualContactInfo,
    InvestNowProfileFormIndividualFinancialInfo,
    InvestNowProfileFormEntityInfo,
    InvestNowProfileFormEntityRepresentativeInfo,
    InvestNowProfileFormEntityOwnerInfo,
    InvestNowProfileFormEntityFinancialInfo,
    InvestNowProfileFormEntityVerificationDocs,
  },
  setup() {
    // Offering ID: FB34EAEB8DA1923B11E7778B26130679

    const route = useRoute();
    const $api = inject("$api") as Api;

    // local data declarations

    // exposed refs
    const offeringId = ref("");
    const investorType = ref();
    const offeringCompany: Ref<OfferingCompany | null> = ref(null);
    const headerImage = ref();
    const offeringStyle = ref({ backgroundColor: "gray" });

    const showInvestorTypeSelector = ref(false);

    // computed values
    const offeringName = computed(() => {
      return offeringCompany?.value?.offering_company_name
        ? offeringCompany?.value?.offering_company_name
        : "offering";
    });

    // api method declarations
    async function loadOfferingCompany() {
      let req: GetOfferingCompany = {
        offering_id: offeringId.value,
      };
      try {
        let { data }: { data: V1ResponseWrapper } =
          await $api.v1.getOfferingCompany(req);
        offeringCompany.value = data.data as OfferingCompany;

        if (offeringCompany.value?.panel_header_color) {
          offeringStyle.value.backgroundColor =
            offeringCompany.value?.panel_header_color;
        }

        await loadOfferingIcon();
      } catch (e) {
        // todo: handle/display error
        console.log(e);
      }
    }

    async function loadOfferingIcon() {
      if (offeringCompany.value?.file_path) {
        let req: PostIcon = {
          file_path: offeringCompany.value?.file_path as string,
        };
        let { data } = await $api.v1.getOfferingHeader(req);
        headerImage.value = window.URL.createObjectURL(data);
      }
    }

    // ui methods
    function loginSuccess(login: Login) {
      console.log(`login: ${login}`);
      showInvestorTypeSelector.value = true;
    }

    function tempLoginSuccess(tempLogin: EmitEmailData) {
      console.log(tempLogin);
      showInvestorTypeSelector.value = true;
    }

    function investorTypeSelected(type: string) {
      console.log(type);
      investorType.value = type;

      // todo: reset investor form data mapping
    }

    function completeProfile() {
      console.log("complete profile");
    }

    // lifecycle hooks
    onMounted(() => {
      offeringId.value = route.params.id as string;

      console.log(offeringId);

      loadOfferingCompany();
    });

    return {
      // refs
      offeringId,
      investorType,
      offeringCompany,
      headerImage,
      offeringStyle,
      showInvestorTypeSelector,
      // computed
      offeringName,
      // ui methods
      loginSuccess,
      tempLoginSuccess,
      investorTypeSelected,
      completeProfile,
    };
  },
});
