<template>
  <Card class="p-my-4 p-mx-6 p-py-2 p-px-4">
    <template #title>Financial Information / Self Assessment</template>
    <template #content>
      <div class="p-grid p-nogutter p-flex-column">
        <!-- Row 1 Entity Total Assets -->
        <div class="p-col">
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col">
              <span class="v1-form-title">Entity Total Assets</span>
            </div>
            <div class="p-col">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col-4">
                  <InputText
                    v-model="totalAssets"
                    type="text"
                    placeholder="Total Assets"
                    class="input-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Row 1 Entity Total Assets -->

        <!-- Row 2 Sole Purpose Securities -->
        <div class="p-col">
          <div class="p-grid p-nogutter">
            <div class="p-col-10" style="margin: auto">
              Was this entity formed specifically to purchase the subject
              securities?
            </div>
            <div class="p-col-2">
              <SelectButton
                v-model="purpose"
                class="toggle-select"
                :options="toggleOptions"
              />
            </div>
          </div>
        </div>
        <!-- End Row 2 Sole Purpose Securities -->

        <!-- Row 3 Experience -->
        <div class="p-col">
          <div class="p-grid p-nogutter">
            <div class="p-col-10" style="margin: auto">
              Do you (the purchaser representative) have sufficient knowledge
              and experience in financial and business matters to evaluate the
              merits and risks of investments?
            </div>
            <div class="p-col-2">
              <SelectButton
                v-model="experience"
                class="toggle-select"
                :options="toggleOptions"
              />
            </div>
          </div>
        </div>
        <!-- End Row 3 Experience -->

        <!-- Row 4 Accredited -->
        <div class="p-col">
          <div class="p-grid p-nogutter">
            <div class="p-col-10" style="margin: auto">
              Are all of the equity owners of this entity accredited investors?
            </div>
            <div class="p-col-2">
              <SelectButton
                v-model="purpose"
                class="toggle-select"
                :options="toggleOptions"
              />
            </div>
          </div>
        </div>
        <!-- End Row 4 Accredited -->

        <!-- Row 5 Status / Investment Amount -->
        <div class="p-col">
          <div class="p-grid p-nogutter">
            <!-- Accreditation Status -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Accreditation Status</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="accreditationStatus"
                        type="text"
                        placeholder="Calculated by System"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Accreditation Status -->

            <!-- Maximum Investment Amount -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Maximum Investment Amount</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="maximumInvestment"
                        type="text"
                        placeholder="Calculated by System"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Maximum Investment Amount -->

            <!-- Calculate Button -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">&nbsp;</div>
                <div class="p-col">
                  <Button
                    class="no-box-shadow"
                    label="Calculate"
                    @click="calculate"
                  />
                </div>
              </div>
            </div>
            <!-- End Calculate Button -->
          </div>
        </div>
        <div class="p-col p-mt-2 p-mb-3">
          Note. Accreditation for Reg D Private Placements will require
          additional verification.
        </div>
        <!-- End Row 7 Status / Investment Amount -->

        <!-- Row 6 Next Button -->
        <div class="p-col p-text-right">
          <Button class="no-box-shadow" label="Next" @click="next" />
        </div>
        <!-- End Row 6 Next Button -->
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormEntityFinancialInfo",
  setup() {
    const toggleOptions = ref(["No", "Yes"]);

    function next() {
      console.log("next");
    }

    return {
      // refs
      toggleOptions,
      // methods
      next,
    };
  },
});
</script>

<style lang="scss" scoped>
.toggle-select::v-deep {
  .p-button {
    width: 50%;

    &:focus {
      box-shadow: none;
    }
  }

  .p-button-label {
    color: black;
  }

  .p-highlight {
    background: lightgrey;
    border-color: lightgrey;

    &:hover {
      border: none;
    }
  }
}
</style>
