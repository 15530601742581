<template>
  <Card class="p-my-4 p-mx-6 p-py-2 p-px-4">
    <template #title>Compliance Information</template>
    <template #content>
      <div class="p-grid p-nogutter p-flex-column">
        <!-- Row 1 Share Holder -->
        <div class="p-col p-mt-3">
          <div class="p-grid p-nogutter">
            <div class="p-col-10 p-pr-3">
              Are you or someone in your household or immediate family a 10%
              shareholder, policymaking officer, or member of the board of
              directors of a publicly traded company?
            </div>
            <div class="p-col-2">
              <SelectButton
                v-model="shareHolder"
                class="toggle-select"
                :options="toggleOptions"
              />
            </div>
          </div>
        </div>
        <!-- End Row 1 Share Holder -->

        <!-- Row 2 Association -->
        <div class="p-col p-mt-4">
          <div class="p-grid p-nogutter">
            <div class="p-col-10 p-pr-3">
              Are you, your spouse, or any other immediate family members,
              including parents, in-laws, and siblings that are dependents,
              employed by or associated with the securities industry (for
              example, Investment Advisor, Sole Proprietor, Partner, Officer,
              Director, Branch Manager or Broker at a Broker-Dealer Firm or
              Municipal Securities Dealer) or a financial regulatory agency,
              such as FINRA or the New York Stock Exchange? If yes, does this
              entity require that you obtain its approval for you to open this
              account?
            </div>
            <div class="p-col-2">
              <SelectButton
                v-model="association"
                class="toggle-select"
                :options="toggleOptions"
              />
            </div>
          </div>
        </div>
        <!-- End Row 2 Association -->

        <!-- Row 3 Next Button -->
        <div class="p-col p-mt-3 p-text-right">
          <Button class="no-box-shadow" label="Next" @click="next" />
        </div>
        <!-- Row 3 Next Button -->
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormComplianceInfo",
  setup() {
    const shareHolder = ref("No");
    const association = ref("No");

    const toggleOptions = ref(["No", "Yes"]);

    function next() {
      console.log("next");
    }

    return {
      //refs
      shareHolder,
      association,
      toggleOptions,
      //methods
      next,
    };
  },
});
</script>

<style lang="scss" scoped>
.toggle-select::v-deep {
  .p-button {
    width: 50%;

    &:focus {
      box-shadow: none;
    }
  }

  .p-button-label {
    color: black;
  }

  .p-highlight {
    background: lightgrey;
    border-color: lightgrey;

    &:hover {
      border: none;
    }
  }
}
</style>
