
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormInvestorType",
  setup(props, { emit }) {
    const investorType = ref();
    const investorTypes = ref(["Individual", "Entity"]);

    // watchers
    watch(investorType, () => {
      emit("typeSelected", investorType.value);
    });

    return {
      investorType,
      investorTypes,
    };
  },
});
