
import { defineComponent, ref, inject, computed, watch } from "vue";

import { Api } from "@/api";
import {
  PostLogin,
  PostTempLogin,
  PostForgotPassword,
  PostValidatePasswordToken,
  PostResetPassword,
  V1ResponseWrapper,
} from "@/api/services/v1Payloads";

import { C } from "@/interfaces/constants";
import { Login, TempLogin } from "@/interfaces/v1/Login";

export interface EmitEmailData {
  email: string;
  tempLogin: TempLogin;
}

export default defineComponent({
  name: "InvestNowProfileFormGetStarted",
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
    offeringId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const $api = inject("$api") as Api;

    let tokenValidationResp: V1ResponseWrapper;

    // exposed refs
    const isCollapsed = ref(false);
    const email = ref();
    const password = ref();
    const token = ref();
    const postedEmail = ref();
    const answer1 = ref();
    const answer2 = ref();
    const answer3 = ref();
    const password1 = ref();
    const password2 = ref();
    const loginRequired = ref(false);
    const tokenRequired = ref(false);
    const resetRequired = ref(false);

    // local constants
    const userExistsError = "User has already registered";

    // watchers
    watch(
      () => props.collapsed,
      (o, n) => {
        isCollapsed.value = n;
      }
    );

    // computed values
    const nextDisabled = computed(() => {
      if (!C.REGEX.EMAIL.test(String(email.value).toLowerCase())) {
        return true;
      }

      return postedEmail.value === email.value;
    });

    // ui methods
    async function checkEmail() {
      const req: PostTempLogin = {
        email: email.value,
        offering_id: props.offeringId,
      };
      try {
        const { data }: { data: V1ResponseWrapper } = await $api.v1.tempLogin(
          req
        );

        if (data.error === userExistsError) {
          loginRequired.value = true;
        } else {
          if (data.status === "success") {
            postedEmail.value = email.value;

            const emitData = {
              email: email.value,
              tempLogin: data.data as TempLogin,
            };

            emit("tempLogin", emitData);
          }
        }
      } catch (e) {
        // todo: handle/display error
        console.log(e);
      }
    }

    async function login() {
      const req: PostLogin = {
        email: email.value,
        password: password.value,
      };

      try {
        const { data }: { data: V1ResponseWrapper } = await $api.v1.login(req);
        console.log(data);
        if (data.status === "success") {
          console.log("successful login");
          emit("login", data.data as Login);
        } else {
          // todo: handle/display error
        }
      } catch (e) {
        // todo: handle/display error
        console.log(e);
      }
    }

    async function forgotPassword() {
      const req: PostForgotPassword = {
        email: email.value,
      };

      try {
        const { data }: { data: V1ResponseWrapper } =
          await $api.v1.forgotPassword(req);

        if (
          data.status === "success" ||
          data.error === "Current token has not expired"
        ) {
          loginRequired.value = false;
          tokenRequired.value = true;
        } else {
          // todo: handle/display error
        }
      } catch (e) {
        // todo: handle/display error
        console.log(e);
      }
    }

    async function validateToken() {
      const req: PostValidatePasswordToken = {
        token: token.value,
      };

      try {
        const {
          data,
        }: {
          data: V1ResponseWrapper;
        } = await $api.v1.validatePasswordResetToken(req);

        tokenValidationResp = data;

        if (
          data.status === "success" ||
          data.error === "Current token has not expired"
        ) {
          loginRequired.value = false;
          tokenRequired.value = false;
          resetRequired.value = true;
        } else {
          // todo: handle/display error
        }
      } catch (e) {
        // todo: handle/display error
        console.log(e);
      }
    }

    async function resetPassword() {
      console.log(tokenValidationResp.data);

      const req: PostResetPassword = {
        token: token.value,
        user_id: tokenValidationResp.data
          ? tokenValidationResp.data["user_id"]
          : "",
        password: password1.value,
        answers: [
          {
            security_question_id: "1",
            answer_text: answer1.value,
          },
          {
            security_question_id: "3",
            answer_text: answer2.value,
          },
          {
            security_question_id: "4",
            answer_text: answer3.value,
          },
        ],
        user_first: null,
        user_last: null,
      };

      try {
        const {
          data,
        }: {
          data: V1ResponseWrapper;
        } = await $api.v1.resetPassword(req);

        console.log(data);

        if (data.status === "success") {
          loginRequired.value = true;
          tokenRequired.value = false;
          resetRequired.value = false;
        } else {
          // todo: handle/display error
        }
      } catch (e) {
        // todo: handle/display error
        console.log(e);
      }
    }

    return {
      // refs
      isCollapsed,
      email,
      password,
      token,
      loginRequired,
      tokenRequired,
      resetRequired,
      answer1,
      answer2,
      answer3,
      password1,
      password2,
      // computed
      nextDisabled,
      // methods
      checkEmail,
      login,
      forgotPassword,
      validateToken,
      resetPassword,
    };
  },
});
