<template>
  <Card class="p-my-4 p-mx-6 p-py-2 p-px-4">
    <template #title>Financial Information / Self Assessment</template>
    <template #content>
      <div class="p-grid p-nogutter p-flex-column">
        <!-- Row 1 Individual/Join -->
        <div class="p-col p-mb-3">
          <!-- Investment Type -->
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">How would you like to invest?</span>
            </div>
            <div class="p-col">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col-4 p-pr-3">
                  <Dropdown
                    v-model="investmentType"
                    class="input-selector"
                    :options="investmentTypes"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- End Investment Type -->
        </div>
        <!-- End Row 1 Individual/Join -->

        <!-- Row 2 Spouse Information -->
        <div class="p-col p-mb-3">
          <div class="p-grid p-nogutter">
            <!-- Spouse Name -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Spouse's Full Name</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="spouseName"
                        type="text"
                        placeholder="Full Name"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Spouse Name -->

            <!-- Spouse Email -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Spouse's Email Address</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="spouseEmail"
                        type="text"
                        placeholder="Email"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Spouse Email -->

            <!-- Spouse SSN -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">
                    Spouse's Social Security Number
                  </span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col">
                      <InputText
                        v-model="spouseSSN"
                        type="text"
                        placeholder="###-##-####"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Primary Email Address -->
          </div>
        </div>
        <!-- End Row 2 Spouse Information -->

        <!-- Row 3 Individual Annual Income -->
        <div class="p-col">
          <div class="p-grid p-nogutter">
            <!-- Individual Annual Income -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Individual Annual Income</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="individualIncome"
                        type="text"
                        placeholder="Annual Income"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Individual Annual Income -->

            <!-- Earned 2 Years -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">&nbsp;</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col-6 p-pr-2">
                      Have you earned at least this income for the past two
                      years?
                    </div>
                    <div class="p-col-6 p-pr-3">
                      <SelectButton
                        v-model="individualEarnedTwoYears"
                        class="width-100 toggle-select"
                        :options="toggleOptions"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Earned 2 Years -->

            <!-- Earn Next Year -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">&nbsp;</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col-6 p-pr-2">
                      Do you expect to earn at least this income for the next
                      year?
                    </div>
                    <div class="p-col-6">
                      <SelectButton
                        v-model="individualEarnedNextYear"
                        class="width-100 toggle-select"
                        :options="toggleOptions"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Earn Next Year -->
          </div>
        </div>
        <!-- End Row 3 Individual Annual Income -->

        <!-- Row 4 Individual Net Worth -->
        <div class="p-col p-mb-3">
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">Individual Net Worth</span>
            </div>
            <div class="p-col">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col-4 p-pr-3">
                  <InputText
                    v-model="individualNetWorth"
                    type="text"
                    placeholder="Net Worth"
                    class="input-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Row 4 Individual Net Worth -->

        <!-- Row 5 Joint Annual Income -->
        <div class="p-col">
          <div class="p-grid p-nogutter">
            <!-- Joint Annual Income -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Joint Annual Income</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="jointIncome"
                        type="text"
                        placeholder="Annual Income"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Joint Annual Income -->

            <!-- Joint Joint Earned 2 Years -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">&nbsp;</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col-6 p-pr-2">
                      Have you earned at least this income for the past two
                      years?
                    </div>
                    <div class="p-col-6 p-pr-3">
                      <SelectButton
                        v-model="jointEarnedTwoYears"
                        class="width-100 toggle-select"
                        :options="toggleOptions"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Joint Earned 2 Years -->

            <!-- Joint Next Year -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">&nbsp;</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col-6 p-pr-2">
                      Do you expect to earn at least this income for the next
                      year?
                    </div>
                    <div class="p-col-6">
                      <SelectButton
                        v-model="jointEarnedNextYear"
                        class="width-100 toggle-select"
                        :options="toggleOptions"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Joint Next Year -->
          </div>
        </div>
        <!-- End Row 5 Joint Annual Income -->

        <!-- Row 6 Joint Net Worth -->
        <div class="p-col p-mb-3">
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">Joint Net Worth</span>
            </div>
            <div class="p-col">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col-4 p-pr-3">
                  <InputText
                    v-model="jointNetWorth"
                    type="text"
                    placeholder="Net Worth"
                    class="input-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Row 6 Individual Net Worth -->

        <!-- Row 7 Experience -->
        <div class="p-col p-mb-3">
          <div class="p-grid p-nogutter">
            <div class="p-col-2">
              Have you had previous experience investing in private placements?
            </div>
            <div class="p-col-2 p-pr-3">
              <SelectButton
                v-model="experience"
                class="width-100 toggle-select"
                :options="toggleOptions"
              />
            </div>
            <div class="p-col"></div>
          </div>
        </div>
        <!-- End Row 7 Experience -->

        <!-- Row 8 Accreditation Status -->
        <div class="p-col">
          <div class="p-grid p-nogutter">
            <!-- Accreditation Status -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Accreditation Status</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="accreditationStatus"
                        type="text"
                        placeholder="Calculated by System"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Accreditation Status -->

            <!-- Maximum Investment Amount -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Maximum Investment Amount</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="maximumInvestment"
                        type="text"
                        placeholder="Calculated by System"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Maximum Investment Amount -->

            <!-- Calculate Button -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">&nbsp;</div>
                <div class="p-col">
                  <Button
                    class="no-box-shadow"
                    label="Calculate"
                    @click="calculate"
                  />
                </div>
              </div>
            </div>
            <!-- End Calculate Button -->
          </div>
        </div>
        <div class="p-col p-mt-2 p-mb-3">
          Note. Accreditation for Reg D Private Placements will require
          additional verification.
        </div>
        <!-- End Row 8 Experience -->

        <!-- Row 9 Next Button -->
        <div class="p-col p-text-right">
          <Button class="no-box-shadow" label="Next" @click="next" />
        </div>
        <!-- End Row 9 Next Button -->
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormIndividualFinancialInfo",
  setup() {
    const investmentType = ref();
    const spouseName = ref();
    const spouseEmail = ref();
    const spouseSSN = ref();
    const individualIncome = ref();
    const individualEarnedTwoYears = ref("Yes");
    const individualEarnedNextYear = ref("Yes");
    const individualNetWorth = ref();
    const jointIncome = ref();
    const jointEarnedTwoYears = ref("Yes");
    const jointEarnedNextYear = ref("Yes");
    const jointNetWorth = ref();
    const experience = ref("No");
    const accreditationStatus = ref();
    const maximumInvestment = ref();

    const investmentTypes = ref(["Individual", "Joint"]);
    const toggleOptions = ref(["No", "Yes"]);

    function calculate() {
      console.log("calculate");
    }

    function next() {
      console.log("next");
    }

    return {
      //refs
      investmentType,
      spouseName,
      spouseEmail,
      spouseSSN,
      individualIncome,
      individualEarnedTwoYears,
      individualEarnedNextYear,
      individualNetWorth,
      jointIncome,
      jointEarnedTwoYears,
      jointEarnedNextYear,
      jointNetWorth,
      experience,
      investmentTypes,
      toggleOptions,
      accreditationStatus,
      maximumInvestment,
      //methods
      calculate,
      next,
    };
  },
});
</script>

<style lang="scss" scoped>
.toggle-select::v-deep {
  .p-button {
    width: 50%;

    &:focus {
      box-shadow: none;
    }
  }

  .p-button-label {
    color: black;
  }

  .p-highlight {
    background: lightgrey;
    border-color: lightgrey;

    &:hover {
      border: none;
    }
  }
}
</style>
