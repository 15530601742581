<template>
  <Card class="p-my-4 p-mx-6 p-py-2 p-px-4">
    <template #title>Entity Owner Information</template>
    <template #content>
      <div class="p-grid p-nogutter p-flex-column">
        <!-- Header -->
        <div class="p-col border-header entity-owner-header">
          <div class="p-grid p-nogutter p-px-5">
            <div class="p-col-11" style="margin: auto">Entity Owners</div>
            <div class="p-col-1 p-text-right">
              <Button
                icon="fas fa-plus"
                class="p-button-rounded p-button-text no-box-shadow"
                @click="addOwner"
              />
            </div>
          </div>
        </div>
        <!-- End Header -->

        <!-- Owner Info -->
        <div
          v-for="(id, index) in owners"
          class="p-col p-px-5 p-py-3 border-owner"
          :key="id"
        >
          <InvestNowFormEntityOwnerInfoDetails :id="id" :index="index" />
        </div>
        <!-- End Owner Info -->

        <!-- Next Button -->
        <div class="p-col p-mt-3 p-text-right">
          <Button class="no-box-shadow" label="Next" @click="next" />
        </div>
        <!-- End Next Button -->
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import InvestNowFormEntityOwnerInfoDetails from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormEntityOwnerInfoDetails.vue";

export default defineComponent({
  name: "InvestNowProfileFormEntityOwnerInfo",
  components: {
    InvestNowFormEntityOwnerInfoDetails,
  },
  setup() {
    const owners = ref([0]);

    let ids = 0;

    function addOwner() {
      ids++;
      owners.value.push(ids);
    }

    function next() {
      console.log("next");
    }

    return {
      // refs
      owners,
      // methods
      addOwner,
      next,
    };
  },
});
</script>

<style lang="scss" scoped>
.border-header {
  border: 1px solid #ddd;
}

.border-owner {
  border: 1px solid #ddd;
  border-top-color: transparent;
}

.entity-owner-header {
  background-color: #f5f5f5;
  font-weight: bold;
}

//.entity-owner-text {
//
//}
</style>
