<template>
  <Card class="p-my-4 p-mx-6 p-py-2 p-px-4">
    <template #title>Investor</template>
    <template #content>
      <div class="p-grid p-nogutter p-flex-column">
        <div class="p-col p-mt-4">
          <div class="p-grid p-nogutter">
            <div class="p-col">
              Which type of investor would you like to create?
            </div>
            <div class="p-col p-text-right">
              <Dropdown
                v-model="investorType"
                class="investor-type-selector p-text-left"
                :options="investorTypes"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormInvestorType",
  setup(props, { emit }) {
    const investorType = ref();
    const investorTypes = ref(["Individual", "Entity"]);

    // watchers
    watch(investorType, () => {
      emit("typeSelected", investorType.value);
    });

    return {
      investorType,
      investorTypes,
    };
  },
});
</script>

<style lang="scss" scoped>
.investor-type-selector {
  min-width: 300px;
}
</style>
