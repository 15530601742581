<template>
  <Card class="p-my-4 p-mx-6 p-py-2 p-px-4">
    <template #title>Suitability Questions</template>
    <template #content>
      <div class="p-grid p-nogutter p-flex-column">
        <!-- Row 1 Timeframe -->
        <div class="p-col p-mt-3">
          <div class="p-grid p-nogutter">
            <div class="p-col-10 p-pr-3">
              While the company anticipates paying an annual dividend, it does
              not expect to have a capital event (return of capital) for at
              least 5 years. What timeframe do you expect a return on your
              investment?
            </div>
            <div class="p-col-2 v1-input-container">
              <Dropdown
                v-model="timeframe"
                class="input-selector"
                :options="timeframeOptions"
              />
            </div>
          </div>
        </div>
        <!-- End Row 1 Timeframe -->

        <!-- Row 2 Risk -->
        <div class="p-col p-mt-3">
          <div class="p-grid p-nogutter">
            <div class="p-col-10 p-pr-3">
              Investments are risky. Do you understand that it is possible that
              you will lose all of your investment?
            </div>
            <div class="p-col-2 v1-input-container">
              <SelectButton
                v-model="risk"
                class="toggle-select"
                :options="toggleOptions"
              />
            </div>
          </div>
        </div>
        <!-- End Row 2 Risk -->

        <!-- Row 3 Experience -->
        <div class="p-col p-mt-3">
          <div class="p-grid p-nogutter">
            <div class="p-col-10 p-pr-3">
              What is your experience level with investments?
            </div>
            <div class="p-col-2 v1-input-container">
              <Dropdown
                v-model="experience"
                class="input-selector"
                :options="experienceOptions"
              />
            </div>
          </div>
        </div>
        <!-- End Row 3 Experience -->
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormSuitability",
  setup() {
    const timeframe = ref();
    const risk = ref("Yes");
    const experience = ref();

    const toggleOptions = ref(["No", "Yes"]);
    const timeframeOptions = ref([
      "One Year",
      "Three Years",
      "Five Years",
      "Seven or More Years",
    ]);
    const experienceOptions = ref([
      "Novice",
      "Intermediate",
      "Experienced",
      "Seven or More Years",
    ]);

    function next() {
      console.log("next");
    }

    return {
      //refs
      timeframe,
      risk,
      experience,
      toggleOptions,
      timeframeOptions,
      experienceOptions,
      //methods
      next,
    };
  },
});
</script>

<style lang="scss" scoped>
.toggle-select::v-deep {
  .p-button {
    width: 50%;

    &:focus {
      box-shadow: none;
    }
  }

  .p-button-label {
    color: black;
  }

  .p-highlight {
    background: lightgrey;
    border-color: lightgrey;

    &:hover {
      border: none;
    }
  }
}
</style>
