
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormIndividualFinancialInfo",
  setup() {
    const investmentType = ref();
    const spouseName = ref();
    const spouseEmail = ref();
    const spouseSSN = ref();
    const individualIncome = ref();
    const individualEarnedTwoYears = ref("Yes");
    const individualEarnedNextYear = ref("Yes");
    const individualNetWorth = ref();
    const jointIncome = ref();
    const jointEarnedTwoYears = ref("Yes");
    const jointEarnedNextYear = ref("Yes");
    const jointNetWorth = ref();
    const experience = ref("No");
    const accreditationStatus = ref();
    const maximumInvestment = ref();

    const investmentTypes = ref(["Individual", "Joint"]);
    const toggleOptions = ref(["No", "Yes"]);

    function calculate() {
      console.log("calculate");
    }

    function next() {
      console.log("next");
    }

    return {
      //refs
      investmentType,
      spouseName,
      spouseEmail,
      spouseSSN,
      individualIncome,
      individualEarnedTwoYears,
      individualEarnedNextYear,
      individualNetWorth,
      jointIncome,
      jointEarnedTwoYears,
      jointEarnedNextYear,
      jointNetWorth,
      experience,
      investmentTypes,
      toggleOptions,
      accreditationStatus,
      maximumInvestment,
      //methods
      calculate,
      next,
    };
  },
});
