<template>
  <Card class="p-my-4 p-mx-6 p-py-2 p-px-4">
    <template #title>Verification Documents</template>
    <template #content>
      <div class="p-grid p-nogutter p-flex-column">
        <!-- Row 1 EIN Verification Letter -->
        <div class="p-col">
          <div class="p-grid p-nogutter">
            <div class="p-col-8" style="margin: auto">
              <span class="document-title">EIN Verification Letter</span><br />
              <span class="document-desc">IRS form 147c or SS-4</span>
            </div>
            <div class="p-col-4 p-text-right">
              <Button
                class="no-box-shadow"
                label="Upload File"
                @click="upload"
              />
            </div>
          </div>
        </div>
        <!-- End Row 1 EIN Verification Letter -->

        <!-- Row 2 Next Button -->
        <div class="p-col p-mt-3 p-text-right">
          <Button class="no-box-shadow" label="Next" @click="next" />
        </div>
        <!-- End Row 2 Next Button -->
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormEntityVerificationDocs",
  setup() {
    function upload() {
      console.log("upload");
    }

    function next() {
      console.log("next");
    }

    return {
      // methods
      upload,
      next,
    };
  },
});
</script>

<style lang="scss" scoped>
.document-title {
  font-weight: bold;
}
.document-desc {
  font-size: 12px;
}
</style>
