<template>
  <Card class="p-my-4 p-mx-6 p-py-2 p-px-4">
    <template #title>Entity Information</template>
    <template #content>
      <div class="p-grid p-nogutter p-flex-column">
        <!-- Row 1 Entity Type/ Name / Start Date -->
        <div class="p-col p-mb-3">
          <div class="p-grid p-nogutter">
            <!-- Entity Type -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Entity Type</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <Dropdown
                        v-model="entityType"
                        class="input-selector"
                        :options="typeOptions"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Entity Type -->

            <!-- Entity Name -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Entity Name</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="entityName"
                        type="text"
                        placeholder="Full Name"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Entity Name -->

            <!-- Entity Start Date -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Entity Start Date</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col">
                      <InputText
                        v-model="startDate"
                        type="text"
                        placeholder="mm/dd/yyy"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Entity Start Date -->
          </div>
        </div>
        <!-- End Row 1 Entity Type/ Name / Start Date -->

        <!-- Row 2 Entity Industry / FinCen Regulated / US Entity -->
        <div class="p-col p-mb-3">
          <div class="p-grid p-nogutter">
            <!-- Entity Industry -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Entity Industry</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <Dropdown
                        v-model="entityIndustry"
                        class="input-selector"
                        :options="industryOptions"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Entity Industry -->

            <!-- Entity FinCen Regulated -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">&nbsp;</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col-6 p-pr-3">
                      Is the entity FinCen regulated?
                    </div>
                    <div class="p-col-6 p-pr-3">
                      <SelectButton
                        v-model="isRegulated"
                        class="toggle-select"
                        :options="toggleOptions"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Secondary Phone Number -->

            <!-- Primary Email Address -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">&nbsp;</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col-6 p-pr-3">
                      Are you a US entity with a TIN/EIN
                    </div>
                    <div class="p-col-6">
                      <SelectButton
                        v-model="hasTIN"
                        class="toggle-select"
                        :options="toggleOptions"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Primary Email Address -->
          </div>
        </div>
        <!-- End Entity Industry / FinCen Regulated / US Entity -->

        <!-- Row 3 Entity TIN -->
        <div class="p-col">
          <div class="p-grid p-nogutter">
            <!-- Entity TIN -->
            <div v-if="hasTIN === 'Yes'" class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Entity TIN</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="entityTIN"
                        type="text"
                        placeholder="EIN/TIN"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Entity TIN -->
            <!-- Spacer -->
            <div v-if="hasTIN === 'Yes'" class="p-col-8"></div>

            <!-- Entity FTIN -->
            <div v-if="hasTIN === 'No'" class="p-col-4 p-mt-3">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col-6 p-pr-3">Do you have a FTIN?</div>
                <div class="p-col-6 p-pr-3">
                  <SelectButton
                    v-model="hasFTIN"
                    class="toggle-select"
                    :options="toggleOptions"
                  />
                </div>
              </div>
            </div>
            <!-- End Entity FTIN -->

            <!-- Entity Disclaimer -->
            <div v-if="hasTIN === 'No'" class="p-col-4 p-mt-3 itin-info-text">
              <p>
                You can continue investing without a FTIN, but please begin the
                <a
                  href="https://www.irs.gov/individuals/international-taxpayers/taxpayer-identification-numbers-tin"
                  target="_blank"
                  >application process with the IRS</a
                >
                as soon as possible.
              </p>
              <p>
                <a href="#">Click here</a> for specific instructions on how to
                complete the process.
              </p>
            </div>
            <!-- End Disclaimer -->

            <!-- Spacer -->
            <div v-if="hasTIN === 'No'" class="p-col-4"></div>
          </div>
        </div>
        <!-- End Row 3 Entity TIN -->

        <!-- Row 4 Next Button -->
        <div class="p-col p-text-right">
          <Button class="no-box-shadow" label="Next" @click="next" />
        </div>
        <!-- Row 4 Next Button -->
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormEntityInfo",
  setup() {
    const entityType = ref();
    const entityName = ref();
    const startDate = ref();
    const entityIndustry = ref();
    const isRegulated = ref("Yes");
    const hasTIN = ref("Yes");
    const entityTIN = ref();
    const hasFTIN = ref("No");

    const toggleOptions = ref(["No", "Yes"]);
    const typeOptions = ref([
      "C Corporation",
      "S Corporation",
      "Limited Liability Corporation",
      "Partnership",
      "Sole Proprietorship",
      "Trust",
      "Estate",
    ]);
    const industryOptions = ref([]);

    // todo: api call to load industries
    // /investor/list-investor-industries

    function next() {
      console.log("next");
    }

    return {
      // refs
      entityType,
      entityName,
      startDate,
      entityIndustry,
      isRegulated,
      hasTIN,
      entityTIN,
      hasFTIN,
      toggleOptions,
      typeOptions,
      industryOptions,
      // methods
      next,
    };
  },
});
</script>

<style lang="scss" scoped>
.toggle-select::v-deep {
  .p-button {
    width: 50%;

    &:focus {
      box-shadow: none;
    }
  }

  .p-button-label {
    color: black;
  }

  .p-highlight {
    background: lightgrey;
    border-color: lightgrey;

    &:hover {
      border: none;
    }
  }
}
</style>
