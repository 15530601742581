
import { defineComponent, ref } from "vue";

import InvestNowFormEntityOwnerInfoDetails from "@/components/v1/invest-now-profile-v1/InvestNowProfileFormEntityOwnerInfoDetails.vue";

export default defineComponent({
  name: "InvestNowProfileFormEntityOwnerInfo",
  components: {
    InvestNowFormEntityOwnerInfoDetails,
  },
  setup() {
    const owners = ref([0]);

    let ids = 0;

    function addOwner() {
      ids++;
      owners.value.push(ids);
    }

    function next() {
      console.log("next");
    }

    return {
      // refs
      owners,
      // methods
      addOwner,
      next,
    };
  },
});
