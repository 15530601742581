<template>
  <Card class="p-my-4 p-mx-6 p-py-2 p-px-4">
    <template #title>Purchaser Representative Information</template>
    <template #content>
      <div class="p-grid p-nogutter p-flex-column">
        <!-- Row 1 Rep. Name / Title / Approval -->
        <div class="p-col p-mb-3">
          <div class="p-grid p-nogutter">
            <!-- Representative Name -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Representative Name</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="repName"
                        type="text"
                        placeholder="Full Name"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Representative Name -->

            <!-- Representative Title -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Representative Title</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="repTitle"
                        type="text"
                        placeholder="Occupation Title"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Representative Title -->

            <!-- Approved Purchase -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">&nbsp;</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col-6">
                      Are you approved to make purchases on behalf of this
                      entity?
                    </div>
                    <div class="p-col-6">
                      <SelectButton
                        v-model="approved"
                        class="toggle-select"
                        :options="toggleOptions"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Approved Purchase -->
          </div>
        </div>
        <!-- End Row 1 Rep. Name / Title / Approval-->

        <!-- Row 2 Company Address -->
        <div class="p-col p-mb-3">
          <div class="p-grid p-nogutter">
            <!-- Address Line 1 / State -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Company Address</span>
                </div>
                <!-- Address Line 1 -->
                <div class="p-col p-pr-3 v1-input-container">
                  <InputText
                    v-model="address1"
                    type="text"
                    placeholder="Address Line 1"
                    class="input-text"
                  />
                </div>
                <!-- End Address Line 1 -->

                <!-- State -->
                <div class="p-col p-pr-3 v1-input-container">
                  <InputText
                    v-model="state"
                    type="text"
                    placeholder="State"
                    class="input-text"
                  />
                </div>
                <!-- End State -->
              </div>
            </div>
            <!-- Address Line 1 / State -->

            <!-- Address Line 2 / Country -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">&nbsp;</span>
                </div>
                <!-- Address Line 2 -->
                <div class="p-col p-pr-3 v1-input-container">
                  <InputText
                    v-model="address2"
                    type="text"
                    placeholder="Address Line 2"
                    class="input-text"
                  />
                </div>
                <!-- End Address Line 2 -->

                <!-- Country -->
                <div class="p-col p-pr-3 v1-input-container">
                  <InputText
                    v-model="country"
                    type="text"
                    placeholder="Country"
                    class="input-text"
                  />
                </div>
                <!-- End Country -->
              </div>
            </div>
            <!-- End Address Line 2 / Country -->

            <!-- City / Zip Code -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">&nbsp;</span>
                </div>
                <div class="p-col v1-input-container">
                  <InputText
                    v-model="city"
                    type="text"
                    placeholder="City"
                    class="input-text"
                  />
                </div>
                <div class="p-col v1-input-container">
                  <InputText
                    v-model="zipCode"
                    type="text"
                    placeholder="Zip Code"
                    class="input-text"
                  />
                </div>
              </div>
            </div>
            <!-- City / Zip Code -->
          </div>
        </div>
        <!-- End Row 2 Company Address -->

        <!-- Row 3 Phone Number /  Office Number / Email Address -->
        <div class="p-col">
          <div class="p-grid p-nogutter">
            <!-- Phone Number -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Representative Phone Number</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="phoneNumber"
                        type="text"
                        placeholder="(201) 555-0123"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Phone Number -->

            <!-- Office Phone Number -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">
                    Representative Office Phone Number (optional)
                  </span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="officeNumber"
                        type="text"
                        placeholder="(201) 555-0123"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Office Phone Number -->

            <!-- Email Address -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Email Address</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col">
                      <InputText
                        v-model="emailAddress"
                        type="text"
                        placeholder="Email Address"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Email Address -->
          </div>
        </div>
        <!-- End Row 3 Phone Number /  Office Number / Email Address -->

        <!-- Row 4 Next Button -->
        <div class="p-col p-text-right">
          <Button class="no-box-shadow" label="Next" @click="next" />
        </div>
        <!-- Row 4 Next Button -->
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormRepresentativeInfo",
  setup() {
    const repName = ref();
    const repTitle = ref();
    const approved = ref("Yes");
    const address1 = ref();
    const address2 = ref();
    const city = ref();
    const state = ref();
    const country = ref();
    const zipCode = ref();
    const phoneNumber = ref();
    const officeNumber = ref();
    const emailAddress = ref();

    const toggleOptions = ref(["No", "Yes"]);

    // todo: api call to load industries
    // /investor/list-investor-industries

    function next() {
      console.log("next");
    }

    return {
      // refs
      repName,
      repTitle,
      approved,
      address1,
      address2,
      city,
      state,
      country,
      zipCode,
      phoneNumber,
      officeNumber,
      emailAddress,
      toggleOptions,
      // methods
      next,
    };
  },
});
</script>

<style lang="scss" scoped>
.toggle-select::v-deep {
  .p-button {
    width: 50%;

    &:focus {
      box-shadow: none;
    }
  }

  .p-button-label {
    color: black;
  }

  .p-highlight {
    background: lightgrey;
    border-color: lightgrey;

    &:hover {
      border: none;
    }
  }
}
</style>
