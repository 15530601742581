
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormIndividualPersonalInfo",
  setup() {
    const firstName = ref();
    const lastName = ref();
    const middleInitial = ref();
    const suffix = ref();
    const gender = ref();
    const dateOfBirth = ref();
    const citizen = ref("Yes");
    const ssn = ref();
    const hasItin = ref("No");
    const itin = ref();

    const genders = ref(["Not Specified", "Male", "Female", "Other"]);
    const toggleOptions = ref(["No", "Yes"]);

    function next() {
      console.log("next");
    }

    return {
      // refs
      firstName,
      lastName,
      middleInitial,
      suffix,
      gender,
      genders,
      citizen,
      dateOfBirth,
      ssn,
      hasItin,
      itin,
      toggleOptions,
      // methods
      next,
    };
  },
});
