<template>
  <Card class="p-my-4 p-mx-6 p-py-2 p-px-4">
    <template #title>Personal Information</template>
    <template #content>
      <div class="p-grid p-nogutter p-flex-column">
        <!-- Row 1 Full Name/Gender -->
        <div class="p-col p-mb-3">
          <div class="p-grid p-nogutter">
            <!-- Full Name Info -->
            <div class="p-col-7">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Full Name</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col-6 p-pr-3">
                      <InputText
                        v-model="firstName"
                        type="text"
                        placeholder="First Name"
                        class="input-text"
                      />
                    </div>
                    <div class="p-col-6 p-pr-3">
                      <InputText
                        v-model="lastName"
                        type="text"
                        placeholder="Last Name"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Full Name Info -->

            <!-- Gender -->
            <div class="p-col-3">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">&nbsp;</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="middleInitial"
                        type="text"
                        placeholder="M.I."
                        class="input-text"
                      />
                    </div>
                    <div class="p-col">
                      <InputText
                        v-model="suffix"
                        type="text"
                        placeholder="Suffix"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--- End Gender -->

            <!-- Gender -->
            <div class="p-col-2 p-pl-3">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Gender</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col">
                      <Dropdown
                        v-model="gender"
                        class="input-selector"
                        :options="genders"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--- End Gender -->
          </div>
        </div>
        <!-- End Row 1 Full Name/Gender -->

        <!-- Row 2 DoB/SSN -->
        <div class="p-col">
          <div class="p-grid p-nogutter">
            <!-- DoB/Citizen -->
            <div class="p-col-7">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Date of Birth</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col-6 p-pr-3">
                      <InputText
                        v-model="dateOfBirth"
                        type="text"
                        placeholder="mm/dd/yyy"
                        class="input-text"
                      />
                    </div>
                    <div class="p-col-3 p-pr-3">
                      Are you a citizen or resident of the United States with a
                      SSN?
                    </div>
                    <div class="p-col-3 p-pr-3">
                      <SelectButton
                        v-model="citizen"
                        class="width-100 citizen-select"
                        :options="toggleOptions"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End DoB/Citizen -->

            <!-- Social Security Number -->
            <div v-if="citizen === 'Yes'" class="p-col-5">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Social Security Number</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col">
                      <InputText
                        v-model="ssn"
                        type="text"
                        placeholder="###-##-####"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Social Security Number -->

            <!-- ITIN -->
            <div v-if="citizen === 'No'" class="p-col-5">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">&nbsp;</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col-6">Do you have an ITIN?</div>
                    <div class="p-col-6">
                      <SelectButton
                        v-model="hasItin"
                        class="width-100 citizen-select"
                        :options="toggleOptions"
                      />
                    </div>
                    <div
                      v-if="hasItin === 'No'"
                      class="p-col-12 p-my-3 itin-info-text"
                    >
                      <p>
                        You can continue investing without an ITIN, but please
                        begin the
                        <a
                          href="https://www.irs.gov/individuals/how-do-i-apply-for-an-itin"
                          target="_blank"
                          >application process with the IRS</a
                        >
                        as soon as possible.
                      </p>
                      <p>
                        <a href="#">Click here</a> for specific instructions on
                        how to complete the process.
                      </p>
                    </div>
                    <div
                      v-if="hasItin === 'Yes'"
                      class="p-col-12 p-mt-3 v1-form-title"
                    >
                      ITIN
                    </div>
                    <div
                      v-if="hasItin === 'Yes'"
                      class="p-col-12 p-my-3 v1-form-title"
                    >
                      <InputText
                        v-model="itin"
                        type="text"
                        placeholder="###-##-####"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End ITIN -->
          </div>
        </div>
        <!-- End Row 2 DoB/SSN -->

        <!-- Row 3 Next Button -->
        <div class="p-col p-text-right">
          <Button class="no-box-shadow" label="Next" @click="next" />
        </div>
        <!-- Row 3 Next Button -->
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormIndividualPersonalInfo",
  setup() {
    const firstName = ref();
    const lastName = ref();
    const middleInitial = ref();
    const suffix = ref();
    const gender = ref();
    const dateOfBirth = ref();
    const citizen = ref("Yes");
    const ssn = ref();
    const hasItin = ref("No");
    const itin = ref();

    const genders = ref(["Not Specified", "Male", "Female", "Other"]);
    const toggleOptions = ref(["No", "Yes"]);

    function next() {
      console.log("next");
    }

    return {
      // refs
      firstName,
      lastName,
      middleInitial,
      suffix,
      gender,
      genders,
      citizen,
      dateOfBirth,
      ssn,
      hasItin,
      itin,
      toggleOptions,
      // methods
      next,
    };
  },
});
</script>

<style lang="scss" scoped>
.citizen-select::v-deep {
  .p-button {
    width: 50%;

    &:focus {
      box-shadow: none;
    }
  }

  .p-button-label {
    color: black;
  }

  .p-highlight {
    background: lightgrey;
    border-color: lightgrey;

    &:hover {
      border: none;
    }
  }
}

.itin-info-text {
  font-size: 12px;
}
</style>
