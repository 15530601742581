<template>
  <Card class="p-my-4 p-mx-6 p-py-2 p-px-4">
    <template #title>Contact Information</template>
    <template #content>
      <div class="p-grid p-nogutter p-flex-column">
        <!-- Row 1 Primary Address -->
        <div class="p-col p-mb-3">
          <!-- Address Info -->
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">Primary Address</span>
            </div>
            <div class="p-col p-mb-3">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col-4 p-pr-3">
                  <InputText
                    v-model="address1"
                    type="text"
                    placeholder="Address Line 1"
                    class="input-text"
                  />
                </div>
                <div class="p-col-4 p-pr-3">
                  <InputText
                    v-model="address2"
                    type="text"
                    placeholder="Address Line 2"
                    class="input-text"
                  />
                </div>
                <div class="p-col-4">
                  <InputText
                    v-model="city"
                    type="text"
                    placeholder="City"
                    class="input-text"
                  />
                </div>
              </div>
            </div>
            <div class="p-col">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col-4 p-pr-3">
                  <InputText
                    v-model="state"
                    type="text"
                    placeholder="State"
                    class="input-text"
                  />
                </div>
                <div class="p-col-4 p-pr-3">
                  <InputText
                    v-model="country"
                    type="text"
                    placeholder="Country"
                    class="input-text"
                  />
                </div>
                <div class="p-col-4">
                  <InputText
                    v-model="zip"
                    type="text"
                    placeholder="Zip Code"
                    class="input-text"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- End Address Info -->
        </div>
        <!-- End Row 1 Primary Address -->

        <!-- Row 2 Phone Numbers/Email -->
        <div class="p-col p-mb-3">
          <div class="p-grid p-nogutter">
            <!-- Primary Phone Number -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Primary Phone Number</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="primaryPhone"
                        type="text"
                        placeholder="(201) 555-0123"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Primary Phone Number -->

            <!-- Secondary Phone Number -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title"
                    >Secondary Phone Number (optional)</span
                  >
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col p-pr-3">
                      <InputText
                        v-model="secondaryPhone"
                        type="text"
                        placeholder="(201) 555-0123"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Secondary Phone Number -->

            <!-- Primary Email Address -->
            <div class="p-col-4">
              <div class="p-grid p-nogutter p-flex-column">
                <div class="p-col p-mb-3">
                  <span class="v1-form-title">Primary Email Address</span>
                </div>
                <div class="p-col">
                  <div class="p-grid p-nogutter v1-input-container">
                    <div class="p-col">
                      <InputText
                        v-model="emailAddress"
                        type="text"
                        placeholder="Email Address"
                        class="input-text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Primary Email Address -->
          </div>
        </div>
        <!-- End Row 2 Phone/Email -->

        <!-- Row 3 Next Button -->
        <div class="p-col p-text-right">
          <Button class="no-box-shadow" label="Next" @click="next" />
        </div>
        <!-- End Row 3 Next Button -->
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormIndividualContactInfo",
  setup() {
    const address1 = ref();
    const address2 = ref();
    const city = ref();
    const state = ref();
    const country = ref();
    const zip = ref();
    const primaryPhone = ref();
    const secondaryPhone = ref();
    const emailAddress = ref();

    function next() {
      console.log("next");
    }

    return {
      // refs
      address1,
      address2,
      city,
      state,
      country,
      zip,
      primaryPhone,
      secondaryPhone,
      emailAddress,
      // methods
      next,
    };
  },
});
</script>

<style lang="scss" scoped>
.investor-type-selector {
  min-width: 300px;
}
</style>
