
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormRepresentativeInfo",
  setup() {
    const repName = ref();
    const repTitle = ref();
    const approved = ref("Yes");
    const address1 = ref();
    const address2 = ref();
    const city = ref();
    const state = ref();
    const country = ref();
    const zipCode = ref();
    const phoneNumber = ref();
    const officeNumber = ref();
    const emailAddress = ref();

    const toggleOptions = ref(["No", "Yes"]);

    // todo: api call to load industries
    // /investor/list-investor-industries

    function next() {
      console.log("next");
    }

    return {
      // refs
      repName,
      repTitle,
      approved,
      address1,
      address2,
      city,
      state,
      country,
      zipCode,
      phoneNumber,
      officeNumber,
      emailAddress,
      toggleOptions,
      // methods
      next,
    };
  },
});
