
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormEntityInfo",
  setup() {
    const entityType = ref();
    const entityName = ref();
    const startDate = ref();
    const entityIndustry = ref();
    const isRegulated = ref("Yes");
    const hasTIN = ref("Yes");
    const entityTIN = ref();
    const hasFTIN = ref("No");

    const toggleOptions = ref(["No", "Yes"]);
    const typeOptions = ref([
      "C Corporation",
      "S Corporation",
      "Limited Liability Corporation",
      "Partnership",
      "Sole Proprietorship",
      "Trust",
      "Estate",
    ]);
    const industryOptions = ref([]);

    // todo: api call to load industries
    // /investor/list-investor-industries

    function next() {
      console.log("next");
    }

    return {
      // refs
      entityType,
      entityName,
      startDate,
      entityIndustry,
      isRegulated,
      hasTIN,
      entityTIN,
      hasFTIN,
      toggleOptions,
      typeOptions,
      industryOptions,
      // methods
      next,
    };
  },
});
