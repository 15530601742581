
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormEntityOwnerInfoDetails",
  props: {
    id: {
      type: Number,
      required: true,
      default: -1,
    },
    index: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  setup(props) {
    const fullName = ref();
    const dateOfBirth = ref();
    const ssn = ref();
    const emailAddress = ref();
    const gender = ref();
    const industry = ref();

    const genderOptions = ref([]);
    const industryOptions = ref([]);

    function remove() {
      console.log(`remove: ${props.id}:${props.index}`);
    }

    function takePhoto() {
      console.log("take photo");
    }

    function uploadPhoto() {
      console.log("upload photo");
    }

    function save() {
      console.log("save");
    }

    return {
      // refs
      fullName,
      dateOfBirth,
      ssn,
      emailAddress,
      gender,
      industry,
      genderOptions,
      industryOptions,
      // methods
      remove,
      takePhoto,
      uploadPhoto,
      save,
    };
  },
});
