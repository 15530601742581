<template>
  <div
    class="p-grid p-nogutter p-flex-column"
    :class="{ 'p-mt-2': index === 0 }"
  >
    <!-- Row 1 Remove -->
    <div class="p-col p-text-right">
      <Button
        v-if="index !== 0"
        icon="fas fa-times"
        class="p-button-rounded p-button-text no-box-shadow"
        @click="remove"
      />
    </div>

    <!-- Row 1 Name / DoB / SSN -->
    <div class="p-col">
      <div class="p-grid p-nogutter">
        <!-- Owner Name -->
        <div class="p-col-4">
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">Owner Name</span>
            </div>
            <div class="p-col">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col p-pr-3">
                  <InputText
                    v-model="fullName"
                    type="text"
                    placeholder="Full Name"
                    class="input-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Owner Name -->

        <!-- Owner Date of Birth -->
        <div class="p-col-4">
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">Owner Date of Birth</span>
            </div>
            <div class="p-col">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col p-pr-3">
                  <InputText
                    v-model="dateOfBirth"
                    type="text"
                    placeholder="mm/dd/yyy"
                    class="input-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Owner Date of Birth -->

        <!-- Owner SSN/ITIN -->
        <div class="p-col-4">
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">Owner SSN/ITIN</span>
            </div>
            <div class="p-col">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col">
                  <InputText
                    v-model="ssn"
                    type="text"
                    placeholder="###-##-####"
                    class="input-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Owner SSN/ITIN -->
      </div>
    </div>
    <!-- End Row 1 Name / DoB / SSN -->

    <!-- Row 2 Email / Gender / Industry -->
    <div class="p-col">
      <div class="p-grid p-nogutter">
        <!-- Email Address -->
        <div class="p-col-4">
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">Owner Email Address</span>
            </div>
            <div class="p-col">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col p-pr-3">
                  <InputText
                    v-model="emailAddress"
                    type="text"
                    placeholder="Owner Email Address"
                    class="input-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Email Address -->

        <!-- Owner Owner Gender -->
        <div class="p-col-4">
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">Owner Gender</span>
            </div>
            <div class="p-col">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col p-pr-3">
                  <Dropdown
                    v-model="gender"
                    class="input-selector"
                    :options="genderOptions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Owner Date of Birth -->

        <!-- Owner SSN/ITIN -->
        <div class="p-col-4">
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">Owner Employment Industry</span>
            </div>
            <div class="p-col">
              <div class="p-grid p-nogutter v1-input-container">
                <div class="p-col">
                  <Dropdown
                    v-model="industry"
                    class="input-selector"
                    :options="industryOptions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Owner SSN/ITIN -->
      </div>
    </div>
    <!-- End Row 2 Email / Gender / Industry -->

    <!-- Row 3 Address -->
    <div class="p-col">
      <div class="p-grid p-nogutter">
        <!-- Address Line 1 / State -->
        <div class="p-col-4">
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">Owner Primary Address</span>
            </div>
            <!-- Address Line 1 -->
            <div class="p-col p-pr-3 v1-input-container">
              <InputText
                v-model="address1"
                type="text"
                placeholder="Address Line 1"
                class="input-text"
              />
            </div>
            <!-- End Address Line 1 -->

            <!-- State -->
            <div class="p-col p-pr-3 v1-input-container">
              <InputText
                v-model="state"
                type="text"
                placeholder="State"
                class="input-text"
              />
            </div>
            <!-- End State -->
          </div>
        </div>
        <!-- Address Line 1 / State -->

        <!-- Address Line 2 / Country -->
        <div class="p-col-4">
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">&nbsp;</span>
            </div>
            <!-- Address Line 2 -->
            <div class="p-col p-pr-3 v1-input-container">
              <InputText
                v-model="address2"
                type="text"
                placeholder="Address Line 2"
                class="input-text"
              />
            </div>
            <!-- End Address Line 2 -->

            <!-- Country -->
            <div class="p-col p-pr-3 v1-input-container">
              <InputText
                v-model="country"
                type="text"
                placeholder="Country"
                class="input-text"
              />
            </div>
            <!-- End Country -->
          </div>
        </div>
        <!-- End Address Line 2 / Country -->

        <!-- City / Zip Code -->
        <div class="p-col-4">
          <div class="p-grid p-nogutter p-flex-column">
            <div class="p-col p-mb-3">
              <span class="v1-form-title">&nbsp;</span>
            </div>
            <div class="p-col v1-input-container">
              <InputText
                v-model="city"
                type="text"
                placeholder="City"
                class="input-text"
              />
            </div>
            <div class="p-col v1-input-container">
              <InputText
                v-model="zipCode"
                type="text"
                placeholder="Zip Code"
                class="input-text"
              />
            </div>
          </div>
        </div>
        <!-- City / Zip Code -->
      </div>
    </div>
    <!-- End Row 3 Address -->

    <!-- Row 4 Save/Photo Button -->
    <div class="p-col p-text-right">
      <Button class="no-box-shadow" label="Save" @click="save" />
    </div>
    <div class="p-col">
      <div class="p-grid p-nogutter p-flex-column">
        <div class="p-col">
          <span class="v1-form-title">Executive's Photo Identification</span>
        </div>
        <div class="p-col">
          <div class="p-grid p-nogutter">
            <div class="p-col-8" style="margin: auto">
              Photo ID of business executive
            </div>
            <div class="p-col-4 p-text-right">
              <Button
                class="no-box-shadow p-mr-2"
                label="Take Photo ID"
                @click="takePhoto"
              />
              <Button
                class="no-box-shadow"
                label="Upload File"
                @click="uploadPhoto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Row 4 Save Button -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InvestNowProfileFormEntityOwnerInfoDetails",
  props: {
    id: {
      type: Number,
      required: true,
      default: -1,
    },
    index: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  setup(props) {
    const fullName = ref();
    const dateOfBirth = ref();
    const ssn = ref();
    const emailAddress = ref();
    const gender = ref();
    const industry = ref();

    const genderOptions = ref([]);
    const industryOptions = ref([]);

    function remove() {
      console.log(`remove: ${props.id}:${props.index}`);
    }

    function takePhoto() {
      console.log("take photo");
    }

    function uploadPhoto() {
      console.log("upload photo");
    }

    function save() {
      console.log("save");
    }

    return {
      // refs
      fullName,
      dateOfBirth,
      ssn,
      emailAddress,
      gender,
      industry,
      genderOptions,
      industryOptions,
      // methods
      remove,
      takePhoto,
      uploadPhoto,
      save,
    };
  },
});
</script>

<style lang="scss" scoped></style>
